/* eslint-disable jsx-a11y/anchor-is-valid */
import Func from "@/app/comFunc/Func";
import Icon from "@/components/comIcon/Icon";
import React from "react";
import { NavLink } from "react-router-dom";
import "./tab.scss";

class Tab extends React.Component {
    constructor(props) {
        super(props);
        window.Tab = this;
        this.state = {
            visible: false,
        };
    }
    _isMounted = false;
    componentDidMount = () => {
        this._isMounted = true;
        window.addEventListener("scroll", this.toggleVisible);
    };
    componentWillUnmount() {
        this._isMounted = false;
    }
    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            this._isMounted && this.setState({ visible: true });
        } else if (scrolled <= 300) {
            this._isMounted && this.setState({ visible: false });
        }
    };
    render() {
        return (
            <div {...this.props} className="tab">
                <div className="tab__container">
                    <NavLink to={Func.getRoute("home")} className="tab__item">
                        <Icon type="fas" className="fa-house-chimney"></Icon>
                        <span>Trang chủ</span>
                    </NavLink>
                    <NavLink to={Func.getRoute("result")} className="tab__item">
                        <Icon
                            type="fas"
                            className="fa-square-poll-vertical"
                        ></Icon>
                        <span>Kết quả</span>
                    </NavLink>
                    {/* <NavLink
                        to={Func.getRoute("promotion")}
                        className="tab__item tab__item-active"
                    >
                        <Icon type="fas" className="fa-gift"></Icon>
                        <span>Khuyến mãi</span>
                    </NavLink> */}
                    <div className="tab__item cskh" id="cskh">
                        <Icon type="fas" className="fa-headset"></Icon>
                        <span>CSKH</span>
                        <div data-id="VPvt9EM5gwr" className="livechat_button">
                            <a href="#">CSKH</a>
                        </div>
                    </div>
                    <NavLink
                        to={Func.getRoute("account")}
                        className="tab__item"
                    >
                        <Icon type="fas" className="fa-circle-user"></Icon>
                        <span>Tài khoản</span>
                    </NavLink>
                </div>
                <div
                    className={
                        "tab__scroll-top" +
                        (this.state.visible ? " tab__scroll-top-active" : "")
                    }
                    onClick={this.scrollToTop}
                >
                    <Icon type="fas" className="fa-arrow-up-to-line"></Icon>
                </div>
            </div>
        );
    }
}

export default Tab;
